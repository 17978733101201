

import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { AnimatePresence, motion } from "framer-motion"
import { Modal, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Webcam from "react-webcam";
import { formatInTimeZone } from "date-fns-tz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppState } from './context/AppContext.js'
import BaseLayout from '../../components/BaseLayout'
import { useGlobalState } from '../../hooks/useCustomization';
import {
    faExclamationTriangle, faCamera, faClose, faCheck, faCameraRotate, faExpand,
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { PageLoader, PageLoaderDots } from '../../components/Elements.js'
import { domainConfig } from "../../assets/config.js"
import { TextWithLineBreaks, dataURItoBlob, getRandomInterval } from "../../components/Functions.js"
import NotyfContext from "../../contexts/NotyfContext.js";
import './assets/style.scss'

const GalleryBoard = () => {
    const { dataApplication, handleExit, setLoading } = useGlobalState();
    const { appState, image, setProps, handleRefreshButton } = useAppState();
    const [showModalAuthorize, setShowModalAuthorize] = useState(true)
    const [devices, setDevices] = React.useState([]);
    const [facingModes] = React.useState(['user', 'environment', 'left', 'right']);
    const [showModalCam, setShowModalCam] = useState(false)
    const [showFullscreen, setShowFullscreen] = useState(false)
    const [cameraSettings, setCameraSettings] = useState([])
    const [updateConfigs, setUpdateConfigs] = useState(0)
    const notyf = useContext(NotyfContext);

    const handleConfirmPicture = () => {
        console.log(image)
        let photo = dataURItoBlob(image);
        const imagefile = new File([photo], appState.titulo + '.jpeg', { type: 'image/jpeg' });
        saveMedia(imagefile, 'image');
    }

    const saveMedia = (file, type) => {
        setLoading(true)
        const formData = new FormData();
        formData.append("file", file);
        axios
            .post(`/api/upload/customizacao/${appState.customizacao_id}`, formData)
            .then((response) => {
                let url = domainConfig.imageServer + '/customizacao/' + appState.customizacao_id + '/' + response.data.message
                let newData = {
                    media: url
                }
                axios.post(`/api/eventos/historyResult`, {
                    evento_id: dataApplication.evento_id,
                    customizacao_id: appState.customizacao_id,
                    json: JSON.stringify(newData),
                }).then((response) => {
                    setProps(prevProps => ({
                        ...prevProps,
                        media: url
                    }));
                    handleRefreshButton()
                    setLoading(false)
                }).catch((error) => {
                    setLoading(false)
                    console.log(error);
                    notyf.open({
                        type: "error",
                        message: error.response?.data?.message ? error.response?.data?.message : 'Houve um erro com o servidor',
                        ripple: true,
                        duration: 30000,
                        dismissible: true,
                    });
                });
            })
            .catch((error) => {
                setLoading(false)
                console.log(error);
                notyf.open({
                    type: "error",
                    message: error.response?.data?.message ? error.response?.data?.message : 'Houve um erro com o servidor',
                    ripple: true,
                    duration: 30000,
                    dismissible: true,
                });
            });
    }

    const handleModalClick = (event) => {
        event.stopPropagation();
        setShowModalAuthorize(false)
    };

    const handleDevices = React.useCallback(
        mediaDevices =>
            setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );

    useEffect(() => {
           const handleKeyDown = (event) => {
               if (event.key === 'Escape' || event.keyCode === 27) {
                setShowFullscreen(false);
               }
           };
           document.addEventListener('keydown', handleKeyDown);
           return () => {
               document.removeEventListener('keydown', handleKeyDown);
           };
       }, [setShowFullscreen]);

    React.useEffect(
        () => {
            navigator.mediaDevices.enumerateDevices().then(handleDevices);
        },
        [handleDevices]
    );

    return (
        <BaseLayout showBackButton={dataApplication.apps.length > 1 ? true : false} backButtonHandler={() => handleExit()}>
            <Modal show={showModalAuthorize}
                style={{ zIndex: 1056 }}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className="justify-content-center" onHide={(() => setShowModalAuthorize(false))}>
                    <Modal.Title>Lei Geral de Proteção de Dados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className="fw-bold"><FontAwesomeIcon className="me-1 text-warning" icon={faExclamationTriangle} size={'xl'} /> Lei nº 12.965/2014</h6>
                    <p>Este app e seus respectivos envolvidos estão ciente dos termos das normas de proteção de dados aplicáveis à espécie, notadamente a Lei nº 12.965/2014
                        (o "Marco Civil da Internet") e Lei nº 13.709/2018 (a "LGPD"), e se comprometem a respeitá-las e cumpri-las.</p>
                    <div className="text-center">
                        <button type="button" className="btn primaryColor primaryColorText mt-3" onClick={handleModalClick}>
                            Autorizo o uso da minha imagem
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showModalCam}
                style={{ zIndex: 1056 }}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className="justify-content-center" onHide={(() => setShowModalCam(false))}>
                    <Modal.Title>Configurações da câmera</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Col} className='mb-3'>
                        <Form.Label>Dispositivo</Form.Label>
                        <Form.Select
                            name={'deviceId'}
                            onChange={(e) => {
                                setCameraSettings((prevState) => ({
                                    ...prevState,
                                    deviceId: e.target.value
                                }));
                                setUpdateConfigs(prevState => prevState + 1)
                            }}
                            value={cameraSettings.deviceId}>
                            {devices.map((device, index) => {
                                return (
                                    <option key={'deviceId' + index} value={device.deviceId}>{device.label || `Device ${index + 1}`}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} className='mb-3'>
                        <Form.Label>Modo da câmera</Form.Label>
                        <Form.Select
                            name={'facingMode'}
                            value={cameraSettings.facingMode}
                            onChange={(e) => {
                                setCameraSettings((prevState) => ({
                                    ...prevState,
                                    facingMode: e.target.value
                                }));
                                setUpdateConfigs(prevState => prevState + 1)
                            }}
                        >
                            {facingModes.map((modes, index) => {
                                return (
                                    <option key={'facingMode' + index} value={modes}>{modes}</option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn primaryColorT" onClick={() => setShowModalCam(false)}>
                        Voltar
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal fullscreen show={showFullscreen}
                style={{ zIndex: 1056 }}
                size="lg"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <CameraComponent key={'PhotoComponent' + updateConfigs} cameraSettings={cameraSettings} handleConfirmPicture={handleConfirmPicture} setShowFullscreen={setShowFullscreen} showBackButton={true} />
            </Modal>
            <AnimatePresence mode="wait">
                <PhotoComponent key={'PhotoComponent' + updateConfigs} cameraSettings={cameraSettings} setShowModalCam={setShowModalCam} setShowFullscreen={setShowFullscreen} handleConfirmPicture={handleConfirmPicture} />
            </AnimatePresence>
        </BaseLayout>
    )
}

const PhotoComponent = ({ cameraSettings, setShowModalCam, setShowFullscreen, handleConfirmPicture }) => {
    const { appState } = useAppState();

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="col">
                {appState.title.status && <div className="col"><h5 className="mb-3"><TextWithLineBreaks text={appState.title.value}></TextWithLineBreaks></h5></div>}
                {appState.description.status && <div className="col"><p><TextWithLineBreaks text={appState.description.value}></TextWithLineBreaks></p></div>}
                <div className="my-4">
                    <button className="btn primaryColor primaryColorText me-2 buttonWithIcon" onClick={() => setShowModalCam(true)}>
                        <span>
                            <FontAwesomeIcon className={`vertically-centered`} size={"lg"} icon={faCameraRotate} />
                        </span>
                    </button>
                    <button className="btn primaryColor primaryColorText buttonWithIcon" onClick={() => setShowFullscreen(true)}>
                        <span>
                            <FontAwesomeIcon className={`vertically-centered`} size={"lg"} icon={faExpand} />
                        </span>
                    </button>
                </div>
            </div>
            <CameraComponent cameraSettings={cameraSettings} handleConfirmPicture={handleConfirmPicture} showBackButton={false}></CameraComponent>
        </motion.div>
    )
}

const CameraComponent = ({ cameraSettings, handleConfirmPicture, setShowFullscreen, showBackButton }) => {
    const { setLoading } = useGlobalState();
    const { appState, image, setImage, handleRefreshButton } = useAppState();
    const webcamRef = React.useRef(null);

    const screenshot = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setLoading(true)
        const img = new Image();
        const frameImg = new Image();
        frameImg.crossOrigin = "anonymous";
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Draw the image with the frame
            canvas.width = frameImg.width; // Frame dimensions
            canvas.height = frameImg.height;

            const scale = Math.max(canvas.width / img.naturalWidth, canvas.height / img.naturalHeight);
            const imgWidth = img.naturalWidth * scale; // New width and height after scaling
            const imgHeight = img.naturalHeight * scale;
            const offsetX = (canvas.width - imgWidth) / 2; // Center the image
            const offsetY = (canvas.height - imgHeight) / 2;

            // Draw the captured image and the frame
            ctx.drawImage(img, offsetX, offsetY, imgWidth, imgHeight);
            ctx.drawImage(frameImg, 0, 0, canvas.width, canvas.height);

            // Export the final image
            const finalImageSrc = canvas.toDataURL('image/png');
            setImage(finalImageSrc);
            setLoading(false)
        };

        frameImg.onload = () => {
            img.src = imageSrc;
        };
        frameImg.src = domainConfig.imageServer + "/customizacao/" + appState.customizacao_id + "/" + appState.apresentation.frame;
    };

    return (
        <AnimatePresence mode="wait">
            {image ? (
                <Modal.Body className="cameraContainerPreview" key={'photocam'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <div>
                        <img src={image} alt="photocam" />
                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" className="btn primaryColor primaryColorText photoButton mx-2" onClick={() => handleRefreshButton()}>
                                <FontAwesomeIcon icon={faClose} size={'xl'} />
                            </button>
                            <button type="button" className="btn primaryColor primaryColorText photoButton mx-2" onClick={() => handleConfirmPicture()}>
                                <FontAwesomeIcon icon={faCheck} size={'xl'} />
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            ) : (
                <motion.div className="cameraContainer" key={'webcam'} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <Webcam
                        key={'webcam2' + cameraSettings.deviceId}
                        ref={webcamRef}
                        videoConstraints={{ deviceId: cameraSettings.deviceId, facingMode: cameraSettings.facingMode }} />
                    <div className="cameraButtonContainer d-flex justify-content-center mt-4">
                        {showBackButton && <button type="button" className="btn primaryColor primaryColorText photoButton mx-2" onClick={() => setShowFullscreen(false)}>
                            <FontAwesomeIcon icon={faArrowLeft} size={'xl'} />
                        </button>}
                        <button type="button" className="btn primaryColor primaryColorText photoButton mx-2" onClick={() => screenshot()}>
                            <FontAwesomeIcon icon={faCamera} size={'xl'} />
                        </button>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default GalleryBoard