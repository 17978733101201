import React, { useReducer, useContext, useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import HelmetStyle from '../components/HelmetStyle.js'
import { useGlobalState } from '../../../hooks/useCustomization.js';
import { domainConfig } from "../../../assets/config.js"

let socket

const AppContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'CONNECTED':
      return {
        ...state,
        isConnected: action.payload,
      }
    case 'setContexto':
      return {
        ...state,
        contexto: action.payload,
      }
    case 'setResults':
      return {
        ...state,
        results: action.payload,
      }
    case 'setParticipants':
      return {
        ...state,
        participants: action.payload,
      }
    default:
      return state;
  }
};

const GameProvider = ({ children }) => {
  const { dataApplication, setLoading } = useGlobalState();
  const [appState, dispatch] = useReducer(reducer, dataApplication.data);
  const [updateMessages, setUpdateMessages] = useState(0)
  const [showPresentation, setShowPresentation] = useState(false)
  const [image, setImage] = useState(false);
  const [props, setProps] = useState({ 'media': null });

  const handleRefreshButton = () => {
    setImage(null);
    setProps({ media: null })
  }

  useEffect(() => {
    if (dataApplication && dataApplication.presenter) {
      socket = io(domainConfig.socketAPI + '?evento_id=' + dataApplication.evento_id + '&aplicativo_id=' + dataApplication.data.aplicativo_id + '&customizacao_id=' + dataApplication.data.customizacao_id, {
        autoConnect: true,
      });
      socket.on('connect', () => {
        console.log('connect')
        dispatch({ type: 'CONNECTED', payload: true });
      });
      socket.on('login', (result) => {
        dispatch({ type: 'setContexto', payload: result });
      });
      socket.on('selectIndex', (result) => {
        dispatch({ type: 'setIndex', payload: result });
      });
      socket.on('disconnect', () => {
        console.log('disconnect')
        dispatch({ type: 'CONNECTED', payload: false });
      });
      socket.on('updateData', () => {
        setUpdateMessages((prevState) => prevState + 1)
      });
      socket.on('getData', (result) => {
        console.log(result)
        let data = processParticipants(result.participants, result.results)
        console.log(data)
        dispatch({ type: 'setParticipants', payload: data.participants });
        dispatch({ type: 'setResults', payload: data.results });
      });

      socket.emit('login');

      return () => {
        if (socket) {
          socket.disconnect();
          console.log('Socket disconnected');
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{
      appState, dispatch, showPresentation, setShowPresentation, props, setProps, image, setImage, handleRefreshButton
    }}>
      <HelmetStyle appState={appState} />
      {children}
    </AppContext.Provider>
  );
};

const getData = () => {
  socket.emit('getData');
};

const updateData = () => {
  socket.emit('updateData');
};

export {
  AppContext,
  GameProvider,
  updateData,
  getData
};

export const useAppState = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within a GlobalStateProvider');
  }
  return context;
};

function processParticipants(participants, results) {
  let newHistory = JSON.parse(JSON.stringify(results))
  newHistory = newHistory.map(historyItem => {
    let parsedItem = JSON.parse(historyItem.json);
    parsedItem.data = historyItem.data;
    parsedItem.resultado_id = historyItem.resultado_id;
    return parsedItem;
  })

  participants.forEach(participant => {
    participant.participantDetails = JSON.parse(participant.json);
    const transformedDetails = {};

    for (const key in participant.participantDetails) {
      const detail = participant.participantDetails[key];
      if (detail.value === false) {
        detail.value = 'Não assinalado'
      } else if (detail.value === true) {
        detail.value = 'Assinalado'
      }
      transformedDetails[detail.label] = detail.value;
    }
    Object.assign(participant, transformedDetails);
    participant.history = []

    newHistory.forEach(historyItem => {
      if (historyItem.visitante_id === participant.visitante_id) {
        participant.history.push(historyItem);
        Object.keys(participant.participantDetails).forEach(key => {
          let element = participant.participantDetails[key]
          let newElements = { [element.label]: element.value }
          Object.assign(historyItem, newElements);
        });
      }
    });
  });

  return { 'participants': participants, 'results': newHistory };
}
